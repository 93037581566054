


export class PurchasingorderLine {
  id: number;
  purchasingorder_id: number;
  product_id: number;
  product: any;
  qty: number;
  normalized_qty: number;
  supplier: any;
  supplier_id: number;
  created_at: Date;
  updated_at: Date;
}
