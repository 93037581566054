import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService extends BaseService {

  fileForPreviewId = new Subject<number>();
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  upload(formData) {
    return this.http.post<any>(`${this.apiUrl + 'file/upload'}`, formData, {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //  catchError(this.handleFileError)
    );
  }



  private handleFileError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return;
  }


  public downloadFIle(fileId: number): Promise<any> {
    return this.getFile<any>(this.apiUrl + 'file/' + fileId);
  }

  uploadThumbnail(ITEM: any, formData: FormData) {
    return this.post<any>(this.apiUrl + 'thumbnail/upload/' + ITEM, formData);
  }
}
